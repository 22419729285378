import React, { useState, useEffect, useRef } from "react";
import {
  Link, useHistory
} from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import {
  InformationCircleIcon
} from '@heroicons/react/outline';

import FeaturePaywallWrapper from "../account/featurePaywallWrapper"


const SubredditStats = ({ subreddit, currentUser, hasStarter }) => {
  const calcs = subreddit.calculated;

  return (
    <FeaturePaywallWrapper featureKey={"subreddit-stats"} className={'max-w-full py-0'} currentUser={currentUser}>
      
      <div className="mb-8 text-xs sm:text-sm">
        <h3 className="text-xs font-semibold text-gray-400 uppercase tracking-wide flex items-center">
          <div>Subreddit Stats</div>
        </h3>
        <div>
          <ReactTooltip id={`modal-stats-tooltip-${subreddit.name}`} place="right" effect="solid" backgroundColor="white" textColor="black" />

          {calcs && Object.keys(calcs).length !== 0 ? (
            <table className="w-full sm:w-auto">
              <thead>
                <tr>
                  <th className="px-1 sm:pl-5 py-1 text-right"></th>
                  <th className="px-1 sm:pl-5 py-1 text-right">Total</th>
                  <th className="px-1 sm:pl-5 py-1 text-right">/ Member</th>
                  <th className="px-1 sm:pl-5 py-1 text-right">
                    <div className="flex items-center">
                      <div>/ Day</div>
                      <InformationCircleIcon className="h-4 w-4 ml-2 text-gray-500" 
                        data-for={`modal-stats-tooltip-${subreddit.name}`} data-tip={`Average per day since beginning of the Subreddit`} />
                    </div>
                  </th>
                  <th className="px-1 sm:pl-5 py-1 text-right items-center">
                    <div className="flex items-center">
                      <div>Percentile</div>
                      <InformationCircleIcon className="h-4 w-4 ml-2 text-gray-500" 
                        data-for={`modal-stats-tooltip-${subreddit.name}`} data-tip={`Comparing this subreddit "Per Day" stats against all other subreddits tracked by GummySearch.`}
                      />
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="pr-1 text-left">Members</td>
                  <td className={`p-1 text-right`}>{calcs.current_members}</td>
                  <td className={`p-1 text-right text-gray-400`}>N/A</td>
                  <td className={`p-1 text-right ${calcs.per_day_members ? '' : 'text-gray-400'}`}>{calcs.per_day_members ? '+' + calcs.per_day_members.toFixed(0) : '--'}</td>
                  <td className={`p-1 text-right ${calcs.percentile_per_day_members ? '' : 'text-gray-400'}`}>{calcs.percentile_per_day_members !== null ? calcs.percentile_per_day_members + '%' : '--'}</td>
                </tr>
                <tr>
                  <td className="pr-1 text-left">Submissions</td>
                  <td className={`p-1 text-right`}>{calcs.current_submissions}</td>
                  <td className={`p-1 text-right ${calcs.per_user_submissions ? '' : 'text-gray-400'}`}>{calcs.per_user_submissions !== null ? calcs.per_user_submissions.toFixed(2) : '--'}</td>
                  <td className={`p-1 text-right ${calcs.per_day_submissions ? '' : 'text-gray-400'}`}>{calcs.per_day_submissions ? '+' + calcs.per_day_submissions.toFixed(0) : '--'}</td>
                  <td className={`p-1 text-right ${calcs.percentile_per_day_submissions ? '' : 'text-gray-400'}`}>{calcs.percentile_per_day_submissions !== null ? calcs.percentile_per_day_submissions + '%' : '--'}</td>
                </tr>
                <tr>
                  <td className="pr-1 text-left">Comments</td>
                  <td className={`p-1 text-right`}>{calcs.current_comments}</td>
                  <td className={`p-1 text-right ${calcs.per_user_comments ? '' : 'text-gray-400'}`}>{calcs.per_user_comments !== null ? calcs.per_user_comments.toFixed(2) : '--'}</td>
                  <td className={`p-1 text-right ${calcs.per_day_comments ? '' : 'text-gray-400'}`}>{calcs.per_day_comments ? '+' + calcs.per_day_comments.toFixed(0) : '--'}</td>
                  <td className={`p-1 text-right ${calcs.percentile_per_day_comments ? '' : 'text-gray-400'}`}>{calcs.percentile_per_day_comments !== null ? calcs.percentile_per_day_comments + '%' : '--'}</td>
                </tr>
              </tbody>
            </table>
          ) : (
            <div className="opacity-50 mt-1">Subreddit stats not calculated yet. This could take up to an hour for new subreddits.</div>
          )}
        </div>
      </div>          

      <div className="mb-4 text-xs sm:text-sm">
        <h3 className="text-xs font-semibold text-gray-400 uppercase tracking-wide flex items-center">
          <div>Growth</div>
        </h3>
        <div>
          {calcs && Object.keys(calcs).length !== 0 ? (
            <table className="w-full sm:w-auto">
              <thead>
                <tr>
                  <th className="px-1 sm:pl-5 py-1 text-right"></th>
                  <th className="px-1 sm:pl-5 py-1 text-right">Daily</th>
                  <th className="px-1 sm:pl-5 py-1 text-right">Weekly</th>
                  <th className="px-1 sm:pl-5 py-1 text-right">Monthly</th>
                  <th className="px-1 sm:pl-5 py-1 text-right flex items-center">
                    <div>Percentile</div>
                    <InformationCircleIcon className="h-4 w-4 ml-2 text-gray-500" 
                      data-for={`modal-stats-tooltip-${subreddit.name}`} data-tip={`Comparing this subreddit's weekly growth against all other subreddits tracked by GummySearch.`}
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                
                <tr>
                  <td className="pr-1 text-left">Members</td>
                  <td className={`p-1 text-right ${calcs.growth_members_daily ? '' : 'text-gray-400'}`}>{calcs.growth_members_daily !== null ? calcs.growth_members_daily.toFixed(2) + '%' : '--'}</td>
                  <td className={`p-1 text-right ${calcs.growth_members_weekly ? '' : 'text-gray-400'}`}>{calcs.growth_members_weekly !== null ? calcs.growth_members_weekly.toFixed(2) + '%' : '--'}</td>
                  <td className={`p-1 text-right ${calcs.growth_members_monthly ? '' : 'text-gray-400'}`}>{calcs.growth_members_monthly !== null ? calcs.growth_members_monthly.toFixed(2) + '%' : '--'}</td>
                  <td className={`p-1 text-right ${calcs.percentile_growth_members_weekly ? '' : 'text-gray-400'}`}>{calcs.percentile_growth_members_weekly !== null ? calcs.percentile_growth_members_weekly + '%' : '--'}</td>
                </tr>
                <tr>
                  <td className="pr-1 text-left">Submissions</td>
                  <td className={`p-1 text-right ${calcs.growth_submissions_daily ? '' : 'text-gray-400'}`}>{calcs.growth_submissions_daily !== null ? calcs.growth_submissions_daily.toFixed(2) + '%' : '--'}</td>
                  <td className={`p-1 text-right ${calcs.growth_submissions_weekly ? '' : 'text-gray-400'}`}>{calcs.growth_submissions_weekly !== null ? calcs.growth_submissions_weekly.toFixed(2) + '%' : '--'}</td>
                  <td className={`p-1 text-right ${calcs.growth_submissions_monthly ? '' : 'text-gray-400'}`}>{calcs.growth_submissions_monthly !== null ? calcs.growth_submissions_monthly.toFixed(2) + '%' : '--'}</td>
                  <td className={`p-1 text-right ${calcs.percentile_growth_submissions_weekly ? '' : 'text-gray-400'}`}>{calcs.percentile_growth_submissions_weekly !== null ? calcs.percentile_growth_submissions_weekly + '%' : '--'}</td>
                </tr>
                <tr>
                  <td className="pr-1 text-left">Comments</td>
                  <td className={`p-1 text-right ${calcs.growth_comments_daily ? '' : 'text-gray-400'}`}>{calcs.growth_comments_daily !== null ? calcs.growth_comments_daily.toFixed(2) + '%' : '--'}</td>
                  <td className={`p-1 text-right ${calcs.growth_comments_weekly ? '' : 'text-gray-400'}`}>{calcs.growth_comments_weekly !== null ? calcs.growth_comments_weekly.toFixed(2) + '%' : '--'}</td>
                  <td className={`p-1 text-right ${calcs.growth_comments_monthly ? '' : 'text-gray-400'}`}>{calcs.growth_comments_monthly !== null ? calcs.growth_comments_monthly.toFixed(2) + '%' : '--'}</td>
                  <td className={`p-1 text-right ${calcs.percentile_growth_comments_weekly ? '' : 'text-gray-400'}`}>{calcs.percentile_growth_comments_weekly ?  calcs.percentile_growth_comments_weekly + '%' : '--'}</td>
                </tr>
                <tr>
                  <td className="pr-1 text-left">Overall</td>
                  <td className={`p-1 text-right ${calcs.growth_daily ? '' : 'text-gray-400'}`}>{calcs.growth_daily !== null ? calcs.growth_daily.toFixed(2) + '%' : '--'}</td>
                  <td className={`p-1 text-right ${calcs.growth_weekly ? '' : 'text-gray-400'}`}>{calcs.growth_weekly !== null ? calcs.growth_weekly.toFixed(2) + '%' : '--'}</td>
                  <td className={`p-1 text-right ${calcs.growth_monthly ? '' : 'text-gray-400'}`}>{calcs.growth_monthly !== null ? calcs.growth_monthly.toFixed(2) + '%' : '--'}</td>
                  <td className={`p-1 text-right ${calcs.percentile_growth_weekly ? '' : 'text-gray-400'}`}>{calcs.percentile_growth_weekly !== null ? calcs.percentile_growth_weekly + '%' : '--'}</td>
                </tr>
              </tbody>
            </table>
          ) : (
            <div className="opacity-50">Growth metrics not calculated yet. This could take up to an hour for new subreddits.</div>
          )}
        </div>
      </div>

    </FeaturePaywallWrapper>
  );
}

export default SubredditStats;
