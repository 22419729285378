import React, { useState } from "react";
import { connect } from 'react-redux';
// import {
//   withRouter,
// } from "react-router-dom";
import Modal from "../common/modal";
import { Helmet } from "react-helmet";

import { InformationCircleIcon } from '@heroicons/react/outline'

import { collectionsService } from '../../services/collections';
import { audiencesOperations } from "../../redux/audiences"

import ErrorBar from "../common/errorBar";


const AudienceInfoModal = ({ closeModal }) => {
  
  return (
    <Modal close={closeModal} widthClass={'max-w-3xl'}>
      <Helmet><title>Audience | Info</title></Helmet>
      
      <div className="flex items-center">
        <InformationCircleIcon className="h-6 w-6 opacity-50 mr-2"/>

        <h3 className="text-lg leading-6 font-medium">
          Welcome to your audience!
        </h3>

        <button className="focus:outline-none ml-auto" onClick={closeModal}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>

      <div className="text-md">
        <div className="mt-4">
          <div className="opacity-50 mb-1">About</div>
          <p>
            Congrats on making your first audience! An audience is simply a collection of Subreddits, but you can do some pretty amazing things from here.
          </p>
        </div> 

        <div className="mt-4">
          <div className="opacity-50 mb-1">Browse Customer Discovery Themes</div>

          <div className="sm:flex space-x-4">
            <div className="flex-1">
              <p className="">
                Click into the categories shown to quickly browse specific kinds of conversations happening in these Subreddits.
              </p>
              <p className="mt-2">
                Use the <span className="px-1.5 py-1 rounded-md relative top-0.5 bg-gray-700 inline-block">
                  <div className="flex items-center text-sm">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="rgba(8, 145, 178,0.15)" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z" />
                    </svg> Save Conversation
                  </div>
                </span> button to save interesting posts to refer back to later. This is useful if you are exploring multiple business ideas, or want to make an outreach list.
              </p>
            </div>
            <div className="flex-1 mt-2 sm:mt-0">
              <iframe width="100%" height="180px" src="https://www.youtube.com/embed/bxLdW8-WaAE" title="YouTube video player" frameborder="0" allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>

        </div> 

        <div className="mt-4">
          <div className="opacity-50 mb-1">Search & Track Keywords</div>

          <div className="sm:flex space-x-4">
            <div className="flex-1">
              <p className="">
                You can search any keyword within these Subreddits!
              </p>
              <p className="mt-2">
                Use the <span className="px-1.5 py-1 rounded-md relative top-0.5 bg-gray-700 inline-block">
                  <div className="flex items-center text-sm">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="rgba(8, 145, 178,0.15)" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                    </svg> Track
                  </div>
                </span> button to be notified of future conversations that match this search.
              </p>
              <p className="mt-2">
                Check out <a className="underline cursor-pointer" href="https://gummysearch.com/insights/keywords-to-track-on-reddit/" target="_blank">this blog post</a> for examples of keywords to track if you are using Reddit for customer acquisition.
              </p>
            </div>
            <div className="flex-1 mt-2 sm:mt-0">
              <iframe width="100%" height="180px" src="https://www.youtube.com/embed/GkSXLMV9CLI" title="YouTube video player" frameborder="0" allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
        </div> 

        {/*<div className="mt-4">
          <div className="opacity-50 mb-1">Explore Subreddits Independently</div>
          <p>
            ...
          </p>
        </div> */}
      </div>

    </Modal>
  );
};


export default AudienceInfoModal;



