import React, { useState, useEffect } from "react"
import {
  Link
} from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import { Popover, Transition } from '@headlessui/react'
import { connect } from 'react-redux';

import ProgressRing from "../common/progressring";
import SelectPopover from "../common/selectPopover";

import Checklist from "./checklist";

import { userOperations } from "../../redux/user"
import { profileService } from '../../services/profile';

import { getIcon } from '../../utils/icons';
import { objectiveOptions } from '../../components/welcome/constants';


// shows popover in sidebar for a user's checklist progress 
const ChecklistPopover = ({currentUser, checklist, updateChecklist}) => {
  // state
  const [objectiveKey, setObjectiveKey] = useState(currentUser && currentUser.profile.objective || "FP");


  // effects
  useEffect(() => {
    if (currentUser && !checklist){
      updateChecklist(currentUser.profile.id);
    }
  }, []); // Gets steps to a user's objective

  // actions
  const saveObjective = (key) => {
    // setLoading(true);
    setObjectiveKey(key);
    profileService.updateProfile(currentUser.profile.id, {
      'objective': key,
    }, (response) => {

      // we updated preferences, get new checklist
      updateChecklist(currentUser.profile.id);
    }, (error) => {
      // setError(error || "Error updating objective")
      // setLoading(false)
    });
  }

  if (!checklist || !currentUser) return ''

  const progress = parseInt(checklist.steps.filter(s => s.complete).length * 100 / checklist.steps.length);

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          {!open ? (
            <ReactTooltip id='launch-guide' effect="solid" backgroundColor="white" textColor="black" />
          ) : ''}

          <Popover.Button
            data-for='launch-guide' data-tip={`Launch Guide`}
            className={`relative flex-shrink-0 inline-flex items-center justify-center h-14 w-14 focus:outline-none focus:ring-transparent focus:border-none rounded-lg ${open ? 'bg-gray-900 text-white' : 'text-gray-400 hover:bg-gray-700'}`}
            onClick={() => {
              if (!open){
                // refetch every time user opens
                updateChecklist(currentUser.profile.id);
              }
            }}
          >
            <span className="sr-only">Launch Guide</span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 w-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M15.59 14.37a6 6 0 0 1-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 0 0 6.16-12.12A14.98 14.98 0 0 0 9.631 8.41m5.96 5.96a14.926 14.926 0 0 1-5.841 2.58m-.119-8.54a6 6 0 0 0-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 0 0-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 0 1-2.448-2.448 14.9 14.9 0 0 1 .06-.312m-2.24 2.39a4.493 4.493 0 0 0-1.757 4.306 4.493 4.493 0 0 0 4.306-1.758M16.5 9a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
            </svg>

            {progress < 100 ? (
              <div className="absolute">
                <ProgressRing
                  radius={20} stroke={2}
                  color={'rgb(52, 211, 153)'}
                  progress={progress}
                  dontDimProgress={true}
                />
              </div>
            ) : ''}
          </Popover.Button>

          <Transition
            show={open}
            as={React.Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              static
              className="absolute z-10 left-20 bottom-0 mt-3 px-2 w-screen max-w-lg sm:px-0"
            >
              <div className="rounded-lg shadow-lg ring-opacity-5 overflow-hidden">
                <div className="relative bg-white p-4">
                  
                  <div className="text-lg">
                    <div className="flex">
                      <h3 className="text-base font-medium opacity-70">Your launch guide</h3>

                      <div className="ml-auto">
                        <SelectPopover
                          options={objectiveOptions}
                          currentOption={objectiveOptions.filter(f => f.key === objectiveKey)[0]}
                          setCurrentOption={(s) => {
                            saveObjective(s.key)
                          }}
                          labelField={'label'}
                          displayClassName={'bg-gray-700 px-2 py-1'}
                        />
                      </div>
                    </div>
                    
                    <div className="mt-1">
                      <Checklist checklist={checklist} className={'p-0 bg-white text-gray-900 hover:bg-gray-100 text-white'}/>
                    </div>
                  </div>

                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}

const mapStateToProps = state => {
  const {
    checklist
  } = state.user;

  return {
    checklist
  }
};

const mapDispatchToProps = (dispatch) => {
  const updateChecklist = (userId, callback, onError) => {
    dispatch(userOperations.updateChecklist(userId, callback, onError))
  };
  return {
    updateChecklist
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistPopover);


