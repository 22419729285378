import React, { useState, useEffect } from "react";

import { QuestionTo } from '@questionto/react';
import { Dialog, Transition, Menu, Popover } from '@headlessui/react'
import {
  LightBulbIcon
} from '@heroicons/react/outline'

import LoaderFancy from "../common/loaderfancy";
import Loader from "../common/loader";
import ProgressRing from "../common/progressring";
// import SelectPopover from "../common/selectPopover";
import ErrorBar from "../common/errorBar";

// import AnalysisConversation from "../language/analysisConversation"
// import AnalysisConversations from "../language/analysisConversations"
import AnalysisClusters from "../language/analysisClusters"
import AiMinutePopover from "../language/aiminutepopover"
import FeaturePaywallWrapper from "../account/featurePaywallWrapper"

import { redditService } from '../../services/reddit';
import { profileService } from '../../services/profile';
import { wordsToMinutes } from '../../utils/language';
// import { charsToMinutes } from '../../utils/language'


// pattern analyzes filtered results for reddit search
// used anywhere we have a "results" tab and filtered results are passed in here to analyze for patterns
const ResultsAnalyzePatterns = ({
  currentUser,
  results,
  filterParams,
  limitOptions, // array of [int] options for limits
  emptyMessage, // if 0 results, will show this on empty message
  type, // can be null (guess from results[0]), but pass in explicitly if "submission-replies" instead of comments
  showTips = true, // default to showing tips
  // ids, // not in use. but eventually if we want to multi-select IDs instead of using filterParams?
}) => {
  const [estimating, setEstimating] = useState(false);
  const [estimate, setEstimate] = useState(null);
  const [analyzing, setAnalyzing] = useState(false);
  const [analysis, setAnalysis] = useState(null);
  const [limit, setLimit] = useState(filterParams && filterParams.limit || 100); // how many to do the analysis on
  const [error, setError] = useState(null);
  const [loadingLimits, setLoadingLimits] = useState(false); 
  const [limits, setLimits] = useState(null); 
  var conversationType = type || "submissions"; // defaults to submissions (but pass in type in case of "submission-replies")
  if (!type && results && results.length && results[0]['_meta']['type'] === 'reddit-comment'){
    conversationType = "comments" // TODO: will this break on bookmarks of multi-types?
  }
  // const conversationIds = results.map(r => r.id);
  const tooShortToAnalyze = results.length < 3 || estimate && estimate.token_count < 50;
  const analysisParams = {
    'reports_bulk': ['clusters'],
    'reports': [],
  };

  var resultsParams = Object.assign(
    filterParams ? Object.assign({}, filterParams) : {}, // filterParams should always be passed in. Ideally query, but also could have "ids" field
    {'type': conversationType}, // always set the type
  )
  if (!resultsParams.ids){
    resultsParams['limit'] = limit; // set the limit if it's not ID-based
  }

  const showSettings = filterParams && limitOptions && limitOptions.length ? true : false;
  var tips = [
    {'title': 'The Patterns feature finds common patterns in large numbers of Reddit posts/comments', 'link': ''},
    {'title': 'Patterns are available on themes, keyword search, tracked keywords, and bookmarks', 'link': ''},
    {'title': 'Usage is measured in AI Minutes, how much time you save by having an AI read for you', 'link': ''},
    // {'title': 'You get 1000 AI minutes per month for free with GummySearch Pro, and can purchase more', 'link': ''},
    {'title': 'You can increase number of posts to analyze by pressing the gear icon (best results with 200)', 'link': ''},
    {'title': 'Explore, bookmark, or download pattern results after calculating', 'link': ''},
    {'title': "Email fed@gummysearch.com with feedback to improve AI Patterns!", 'link': ''},
  ]

  useEffect(() => {
    if (!estimate && !analysis && results && currentUser.features.analyze){
      fetchEstimate()
    }
  }, [results]); // fetch analysis estimate when we load

  useEffect(() => {
    if (estimate && !estimating && limit !== estimate.results_limit){
      fetchEstimate()
    }
  }, [limit]); // refetch estimate due to limit change

  // actions
  const fetchEstimate = () => {
    if (estimating) return;

    // fetch estimate for the analysis
    setEstimating(true)
    setAnalyzing(false)
    setEstimate(null)
    setAnalysis(null)
    setError(null)
    redditService.analyzeConversationsEstimate(resultsParams, analysisParams, (response) => {
      setEstimating(false)
      setEstimate(response.data);
      getSubscriptionLimits()
    }, (error, response) => {
      console.error(response)
      setEstimating(false)
      setError('Error estimating analysis')
    });
  }

  const fetchAnalysis = () => {
    if (analyzing) return;

    // fetch estimate for the analysis
    setAnalyzing(true)
    setAnalysis(null)
    setError(null)
    redditService.analyzeConversations(resultsParams, Object.assign(analysisParams, {'estimate_hash': estimate.estimate_hash}), (response) => {
      setAnalyzing(false);
      setAnalysis(response.data);
    }, (error, response) => {
      setAnalyzing(false)
      if (error.response && error.response.status === 500){
        setError("Error running analysis")
      } else {
        setError(response || "Error running analysis")
      }
    });
  }

  const getSubscriptionLimits = () => {
    if (loadingLimits) return;

    // fetch the limits & current usage of the account
    setLoadingLimits(true)
    profileService.getLimits((response) => {
      setLimits(Object.assign(
        response.data,
        {
          'percent_ai_minutes_used': Math.min(100 * response.data.ai_minutes_used / response.data.ai_minutes_allowed, 100)
        }
      ));
      setLoadingLimits(false)
    })
  }

  const fetchPastAnalysis = (hash) => {
    if (analyzing) return;

    // fetch estimate for the analysis
    setAnalyzing(true)
    setAnalysis(null)
    setError(null)
    redditService.getPastAnalysis(hash, (response) => {
      setAnalyzing(false)
      setAnalysis(response.data);
    }, (error, response) => {
      console.error(response)
      setAnalyzing(false)
      setError('Error fetching past analysis')
    });
  }


  const reset = () => {
    setEstimate(null);
    setAnalysis(null);
    fetchEstimate();
  }

  const renderCost = () => {
    // render cost vis & popover (used twice)
    if (!estimate || !limits) return '';

    return (
      <AiMinutePopover limits={limits} minutes={wordsToMinutes(estimate.token_count)} >
        <div className="flex items-center">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 ml-0.5 mr-1 opacity-50">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <div className="mr-1 text-xs opacity-50">AI Minutes: </div>
          <div className="mr-1 relative rounded-full bg-gray-800 border-2 border-gray-700">
            {limits ? (
              <ProgressRing
                radius={14} stroke={2}
                color={limits.percent_ai_minutes_used >= 100 ? '#f59e0c' : '#0a91b1'}
                progress={limits.percent_ai_minutes_used}
              />
            ) : ''}
            
            <span className="text-xs w-full justify-center absolute top-2 flex items-center">
              <span>{wordsToMinutes(estimate.token_count)}</span>
            </span>
          </div>
        </div>
      </AiMinutePopover>
    )
  }

  return (
    <FeaturePaywallWrapper featureKey={"pattern-finder"} className={'max-w-full'} currentUser={currentUser}>
      {estimating ? (
        <div className="w-full flex p-4 bg-gray-800 rounded-lg">
          <div>
            <span className="mr-2">Summarize {Math.max(results && results.length || 0, limit)} {conversationType}</span>
            <span className="hidden sm:block opacity-50 text-sm">
              Estimating...
            </span>
          </div>
          <div className="ml-auto p-2"><Loader /></div>
        </div>
      ) : analyzing ? (
        <div className="h-96 relative p-4 rounded-lg">
          <div className="h-48 relative">
            <LoaderFancy message={estimate && estimate.loading_time ? `Hang tight, this should take about ${estimate.loading_time} seconds` : ''}
              special={true}
            />
          </div>
        </div>
      ) : estimate && !analysis ? (
        <div className="w-full p-4 bg-gray-800 rounded-lg">
          <div className="flex flex-wrap">
            <div>
              <div className="mr-2 flex items-center relative">
                <div>Summarize {estimate.results_count} {conversationType}</div>

                {showSettings ? (
                  <Popover className="relative h-5 left-1">
                    {({ open, close }) => (
                      <>
                        <Popover.Button
                          className={`flex-shrink-0 inline-flex items-center justify-center ml-1 focus:outline-none focus:ring-transparent focus:border-none rounded-lg text-gray-500 hover:text-gray-300`}
                        >
                          <span className="sr-only">Settings</span>
                          <svg  className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z" />
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                          </svg>

                        </Popover.Button>

                        <Transition
                          show={open}
                          as={React.Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel static className={`absolute left-10 -top-2 z-50 w-72 bg-white text-black p-4 rounded-md overflow-hidden shadow-lg bg-white flex flex-col space-y-4`}>
                            {limitOptions ? (
                              <div>
                                <div className="text-sm opacity-50 mb-1">
                                  <div className="uppercase font-semibold ">Count</div>
                                  <div className="text-xs">Increase to get more patterns</div>
                                </div>
                                
                                <div className="flex">
                                  {limitOptions.map(s => (
                                    <div key={s} className="relative py-2 flex items-center ml-2 mr-4">
                                      <div className="flex items-center h-5">
                                        <input id={`limit-${s}`} name="limit" type="radio"
                                          className="focus:ring-cyan-500 h-4 w-4 text-cyan-600 cursor-pointer border-gray-800 bg-gray-600"
                                          checked={parseInt(limit) === s} onChange={(e) => setLimit(s)}
                                        />
                                      </div>
                                      <label htmlFor={`limit-${s}`} className="ml-2 cursor-pointer">
                                        <span className="block text-sm font-medium flex items-center">{s}</span>
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            ) : ''}
                            
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>
                ) : ''}
              </div>
              <div className="block opacity-50 text-sm">
                Save {wordsToMinutes(estimate.token_count)} min by not reading {estimate.token_count} words
              </div>
            </div>
            <div className="ml-auto">
              {/*<div className="text-sm text-center p-2 opacity-75 font-semibold">Save 0 minutes</div>*/}
              
              {!estimate.past_analysis ? (
                <div className="flex items-center space-x-2">

                  { renderCost() }

                  <button type="button" className="inline-flex bg-green-500 px-3 py-3 shadow-sm text-base text-white font-medium rounded-md opacity-90 hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                    onClick={fetchAnalysis}
                    disabled={tooShortToAnalyze}
                  >
                    {/*<span>{wordsToMinutes(estimate.token_count)}</span>*/}
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 ml-0.5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
                    </svg>
                    {tooShortToAnalyze ? (
                      <span>Too Short</span>
                    ) : (
                      <span>Find Patterns</span>
                    )}
                  </button>
                </div>
              ) : (
                <div className="flex items-center space-x-2">
                  { renderCost() }

                  <button type="button" className="flex items-center bg-gray-700 px-3 py-3 shadow-sm text-sm text-white font-medium rounded-md opacity-90 hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                    onClick={fetchAnalysis}
                    disabled={tooShortToAnalyze}
                  >
                    {/*<span>{wordsToMinutes(estimate.token_count)}</span>*/}
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 mx-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
                    </svg>

                    <span className="ml-2">Re-calculate</span>
                  </button>

                  <button type="button" className="inline-flex bg-cyan-500 px-3 py-3 shadow-sm text-sm text-white font-medium rounded-md opacity-90 hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                    onClick={() => fetchPastAnalysis(estimate.past_analysis.hash)}
                    // disabled={tooShortToAnalyze}
                  >
                    {/*<svg className="-ml-1 mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                    </svg>*/}
                    <svg className="-ml-1 mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75" />
                    </svg>

                    <span>View Result</span>
                  </button>
                </div>
              )}
              
            </div>
          </div>
        </div>
      ) : !analyzing && analysis ? (
        <div className="rounded-lg">
          <AnalysisClusters
            analysis={analysis}
            reset={reset}
            limitsConfigurable={limitOptions && limitOptions.length > 1}
            emptyMessage={emptyMessage}
          />
        </div>
      ) : ''}

      {showTips ? (
        <div className="pt-4 mt-4 border-t border-gray-800 max-w-3xl mx-auto">
          <div className="">
            <label className="text-base font-medium mb-1 text-gray-500">
              Patterns Tips
            </label>
            <div>
              {tips.map(t => (
                <div key={t.title} className="bg-gray-800 p-2 sm:p-3 rounded-md mb-2 flex items-center">
                  <LightBulbIcon className="h-4 w-4 mr-2 opacity-50 flex-shrink-0" aria-hidden="true" />
                  <div className="text-xs sm:text-sm text-gray-400">{t.title}</div>
                  {/*{t.link ? (
                    <a href={t.link} target="_blank" className="ml-auto pl-2">
                      <ExternalLinkIcon className="h-6 w-6 opacity-80 hover:opacity-100"/>
                    </a>
                  ) : ''}*/}
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : ''}
      

      {error ? (
        <ErrorBar error={error} setError={setError}
          onRetry={estimate ? fetchAnalysis : fetchEstimate} // retry one that failed
        />
      ) : ''}  
    </FeaturePaywallWrapper>
  );
}

export default ResultsAnalyzePatterns;

