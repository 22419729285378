import { postRequest, getRequest, patchRequest, deleteRequest } from '../utils/axios';
import { toQueryString } from '../utils/urls';
import axios from 'axios';


export const collectionsService = {
    getCollections,
    getPublicCollections,
    getCollection,
    updateCollection,
    createCollection,
    deleteCollection,
    getCollectionItems,
    addCollectionItems,
    deleteCollectionItem,
    getCollectionItemsBulk,
    getCollectionTopics,
    getCollectionTopic,
    getCollectionThemes,
    getCollectionTheme,
    getCollectionTheme,
    getSimilar,
    // createCollection,
};

function getCollections(onSuccess, onError) {
  return getRequest(
    `/api/v1/collections/`,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function getPublicCollections(onSuccess, onError) {
  return getRequest(
    `/api/v1/collections/public/`,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function getCollection(hash, onSuccess, onError) {
  return getRequest(
    `/api/v1/collections/${hash}/`,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function updateCollection(hash, data, onSuccess, onError) {
  return patchRequest(
    `/api/v1/collections/${hash}/`, data,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}


function deleteCollection(hash, onSuccess, onError) {
  return deleteRequest(
    `/api/v1/collections/${hash}/`,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function createCollection(name, onSuccess, onError) {
  return postRequest(
    `/api/v1/collections/`,
    {'name': name},
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function getCollectionItems(hash, onSuccess, onError) {
  return getRequest(
    `/api/v1/collection-items/?collection__hash=${hash}`,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function getCollectionTopics(hash, params, onSuccess, onError) {
  return getRequest(
    `/api/v1/collections/${hash}/topics/${toQueryString(params)}`,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function getCollectionTopic(hash, params, onSuccess, onError) {
  return getRequest(
    `/api/v1/collections/${hash}/topic/${toQueryString(params)}`,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function getCollectionThemes(hash, params, onSuccess, onError) {
  return getRequest(
    `/api/v1/collections/${hash}/themes/${toQueryString(params)}`,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function getCollectionTheme(hash, params, onSuccess, onError) {
  return getRequest(
    `/api/v1/collections/${hash}/theme/${toQueryString(params)}`,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}


// this ideally would use the "hashes", but it doesn't it just gets all for the user
function getCollectionItemsBulk(hashes, onSuccess, onError) {
  return getRequest(
    // `/api/v1/collection-items/?collection__hash__in=${hashes.join(',')}`,
    `/api/v1/collection-items/`,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function addCollectionItems(hash, items, onSuccess, onError) {
  return postRequest(
    `/api/v1/collection-items/bulk_create/`,
    {'collection_hash': hash, 'items': items},
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function deleteCollectionItem(id, onSuccess, onError) {
  return deleteRequest(
    `/api/v1/collection-items/${id}/`,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function getSimilar(hash, onSuccess, onError) {
  return getRequest(
    `/api/v1/collections/${hash}/similar/`,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

// function createCollection(redirect_type, platform_id, platform_name, onSuccess, onError) {
//   return postRequest(
//     `/api/v1/redirects/`,
//     {
//       'redirect_type': redirect_type,
//       'platform_id': platform_id,
//       'platform_name': platform_name,
//     },
//     (response) => {
//       if (onSuccess){
//         return onSuccess(response)
//       }
//     },
//     (error, response) => {
//       if (onError){
//         return onError(error, response)
//       }
//     }
//   )
// }

