import React, { useState, useEffect } from "react";
import {
  Link,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import Toggle from 'react-toggle'

import { CheckIcon, XIcon, CalendarIcon } from '@heroicons/react/solid'
import CheckMark from '@heroicons/react/solid/CheckIcon';
import BriefcaseIcon from '@heroicons/react/solid/BriefcaseIcon';
import UserGroupIcon from '@heroicons/react/solid/UserGroupIcon';
import EmojiHappyIcon from '@heroicons/react/solid/EmojiHappyIcon';

import Modal from "../common/modal";
import ErrorBar from "../common/errorBar";
import FeaturePopover from '../common/featurePopover'

import { paymentService } from '../../services/payment';
import { trackEvent } from '../../utils/tracking';
import { capitalizeFirst } from '../../utils/text'
import { toQueryString, getParamsFromUrl } from '../../utils/urls';

import { PLANS, TRIAL_PRICES, TRIAL_LENGTH } from './constants'


const SubscriptionSelect = ({
  closeModal,
  fetchCurrentUser,
  currentUser,
  setSuccess,
  setRefetchLimits,
  trackingFeature,
  showFree,
  showTeam,
  onSelectFree,
  cancelUrl, // after stripe redirect
  successUrl, // after stripe redirect
}) => {
  // state
  const [checkingOut, setCheckingOut] = useState(null);
  const [showLimits, setShowLimits] = useState(false);
  const [error, setError] = useState(null);
  const currentPlanKey = (currentUser.subscription && currentUser.subscription.key) || '';
  const currentBillingCycleKey = (currentUser.subscription && currentUser.subscription.billing_cycle) || 'monthly';
  const eligibleForTrialSubscription = currentUser.trial.eligible_for_trial_subscription || false;
  const urlParams = getParamsFromUrl();

  // plan options
  const plans = PLANS.filter(p => {
    if (p.key === 'free' && !showFree) return false;
    if (p.key === 'team' && !showTeam) return false;
    return true;
  });
  const currentPlan = plans.filter(t => t.key === currentPlanKey)[0];
  const planFromUrl = urlParams.plan ? plans.filter(t => t.key === urlParams.plan)[0] : null;
  const [selectedPlan, setSelectedPlan] = useState(planFromUrl || currentPlan || plans[0]); // start on starter if they don't have one already
  
  // billing cycle options
  var billingCycles = [
    {'key': 'monthly', 'title': 'Monthly', 'frequency': '/month', 'totalFrequency': '/month'},
    {'key': 'yearly', 'title': 'Yearly', 'frequency': '/month', 'totalFrequency': '/year', 'discount': ' - 33% off'},
  ];
  if (currentUser && currentUser.features.account_subscription_ltd){
    billingCycles.push({'key': 'lifetime', 'title': 'Lifetime', 'frequency': '', 'totalFrequency': ''})
  }
  const currentBillingCycle = billingCycles.filter(t => t.key === currentBillingCycleKey)[0];
  const [billingCycle, setBillingCycle] = useState(currentBillingCycle || billingCycles[0]);

  // available features
  const allFeatures = plans[plans.length - 1].features;
  const currentPlanFeatures = selectedPlan.features;

  // actions
  const selectPlan = (order_type, billing_cycle) => {

    // should never come here
    if (currentPlan && (order_type === currentPlan) && (billing_cycle === currentBillingCycleKey)){
      alert("You are already on this plan")
      return
    }

    // if going from a monthly/annual to lifetime, ask them to cancel existing plan first
    if (currentPlan && currentPlan !== 'free' && currentBillingCycleKey !== 'lifetime' && billing_cycle === 'lifetime'){
      alert("To purchase a lifetime plan, please cancel your existing subscription first")
      return
    }

    // if having exsiting lifetime subscription and wants to change to monthly other plan, ask to cancel first
    if (currentPlan && currentPlan !== 'free' && currentBillingCycleKey === 'lifetime' && billing_cycle !== 'lifetime'){
      alert("You already have a lifetime subscription. If you would like to switch to a monthly subscription, cancel that lifetime subscription first.")
      return
    }

    if (order_type === "free"){
      if (onSelectFree){
        return onSelectFree()
      } else {
        alert("Can't select free tier here");
        return;
      }
    }

    // already have a monthly/annual subscription, go modify
    if (currentPlan && currentPlan !== 'free' && (["monthly", "yearly"].includes(billing_cycle)) && (["monthly", "yearly"].includes(currentBillingCycleKey))){
      const r = window.confirm("Are you sure you want to update your current subscription plan?");
      if (r){
        setError(null);
        setCheckingOut(order_type);
        paymentService.modifyPlan(
          {'product': order_type, 'billing_cycle': billing_cycle},
          (response) => {

          // made the update, refetch user config with updates
          setSuccess && setSuccess("Subscription updated!");
          closeModal && closeModal();

          document.getElementById('main').scrollTo(0,0);
          fetchCurrentUser((response) => {
            setCheckingOut(false);
            trackEvent("Modified Plan", {
              "plan": response.subscription.key,
              "billing_cycle": response.subscription.billing_cycle
            });
            setRefetchLimits && setRefetchLimits(new Date());
          });
        }, (error, response) => {
          setCheckingOut(null);
          setError(response);
          document.getElementById('main').scrollTo(0,0);
        });
      }
      return;
    }

    // otherwise go down the stripe checkout flow (either subscription or lifetime)
    setError(null);
    setCheckingOut(order_type);
    const affiliateReferrer = typeof(window.Rewardful) !== "undefined" ? window.Rewardful.referral : '';
    paymentService.initiateCheckout(
      {
        'product': order_type,
        'billing_cycle': billing_cycle,
        'affiliate_referrer': affiliateReferrer,
        'trial': eligibleForTrialSubscription,
        'feature': trackingFeature || "Subscription Page",
        'cancel_url': cancelUrl,
        'success_url': successUrl,
      },
    (response) => {
      if (response.data.redirect_url){
        window.location.href = response.data.redirect_url
      } else {
        alert("Missing stripe redirect url")
      }
    }, (error, response) => {
      setCheckingOut(null);
      setError(response);
    });
  };

  // rendering
  const getPlanIcon = (plan, className) => {
    const TabIcon = plan.icon;
    return <TabIcon className={className} />
  }

  return (
    <div id="stripe-plans" className="mx-auto py-4">
      
      {error ? (
        <div className="max-w-3xl mx-auto p-2 text-semibold bg-red-500 rounded-md my-4">{error}</div>
      ) : ''}

      {billingCycles && billingCycles.length > 1 ? (
        <div className="md:m-auto max-w-3xl">
          <div className="flex mb-4">
            <div className="ml-auto flex items-center space-x-2">
              <div className="mr-1 text-green-400 text-sm font-semibold">Enable 33% Annual Discount</div>
              <Toggle
                defaultChecked={billingCycle && billingCycle.key === 'yearly'}
                // className="toggle-darkmode"
                icons={{
                  checked: <CheckIcon className="h-3 w-3"/>,
                  unchecked: null
                }}
                onChange={(e) => {
                  setBillingCycle(e.target.checked ? billingCycles[1] : billingCycles[0])
                }}/>


              {/*{billingCycles.map(b => (
                <div key={b.key} className={`px-2 py-1 font-medium text-sm rounded-md ${b.key === billingCycle.key  ? 'bg-gray-600 text-white' : 'bg-gray-700 text-gray-400 cursor-pointer hover:bg-gray-600'}`}
                  onClick={() => setBillingCycle(b)}
                >
                  {b.title}{b.discount || ''}
                </div>
              ))}*/}
            </div>
          </div>
        </div>
      ) : ''}

      <div className="md:m-auto max-w-3xl">
        <div className="sm:text-center">
          <div className="mt-[60px]">
            <div className="flex flex-col justify-center md:flex-row">
              {/* options for plans */}
              <div className="flex-1 flex flex-col space-y-3 md:mr-4">
                {plans.map(plan => (
                  <div key={plan.name}
                    onClick={() => setSelectedPlan(plan)}
                    className={`flex-1 cursor-pointer bg-gray-700 rounded-3xl p-4 ${
                      plan.name == selectedPlan.name ? 'border-2 border-cyan-500' : 'border-2 border-transparent'
                    }`}
                  >
                    <div className="flex">
                      <div>
                        <span className="flex items-center justify-center w-10 h-10 rounded-full bg-cyan-500">
                          <span>{getPlanIcon(plan, "h-6 w-6")}</span>
                        </span>
                      </div>
                      <div className="w-auto ml-4 text-left">
                        <div className="text-xl font-medium text-slate-headline flex items-center">
                          <div className="flex items-center">
                            {plan.name}
                            {/*{plan.popular ? (
                              <span className="p-1 px-2 rounded-full bg-gray-600 text-white text-xs ml-2">most popular</span>
                            ) : ''}*/}
                          </div>
                          <div className="ml-auto font-bold">
                            <div className="ml-auto font-bold">
                              {billingCycle.key === 'yearly' && plan.key !== 'free' ? (
                                <span className="line-through text-gray-400 ml-2 mr-1 text-base">${plan.price['monthly']}</span>
                              ) : ''}
                              <span className="text-green-400">
                                ${plan.price[billingCycle.key]}
                                {billingCycle.key !== 'lifetime' ? '/mo' : ''}
                              </span>
                            </div>
                          </div>
                        </div>
                        <p className="text-base mt-2 text-md text-gray-200">{plan.description}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {/* selected plan section */}
              <div className="flex-1">
                <div className="p-4 px-6 bg-gray-700 rounded-3xl mt-6 md:mt-0 flex flex-col">
                  {/*<div className="flex items-center justify-center w-12 h-12 m-auto rounded-full bg-cyan-500">
                    {getPlanIcon(selectedPlan, "h-8 w-8")}
                  </div>*/}
                  <p className="text-xl font-semibold capitalize">
                    {selectedPlan.name} Plan - 
                    {/*{selectedPlan.price[billingCycle.key + 'Upcoming'] ? (
                      <span className="line-through text-gray-600 ml-2">${selectedPlan.price[billingCycle.key + 'Upcoming']}</span>
                    ) : ''}*/}
                    <span> ${selectedPlan.price[billingCycle.key + 'Total']}{billingCycle['totalFrequency']}</span>
                  </p>
                  <div className="mt-4 text-left mb-4 text-lg">
                    <ul>
                      {allFeatures.map(f => (
                        <li key={f.id} className={`flex items-center w-full mb-2 ${currentPlanFeatures.map(f => f.id).includes(f.id) ? '' : 'opacity-20'}`}>
                          {currentPlanFeatures.map(f => f.id).includes(f.id) ? (
                            <CheckMark className="w-5 h-5 mr-2" />
                          ) : (
                            <XIcon className="w-5 h-5 mr-2" />
                          )}
                          <span className="w-full text-gray-100">
                            <FeaturePopover id={f.id} label={f.label} />
                            {currentPlanFeatures.map(f => f.id).includes(f.id) && f.count ? (
                              <span className="ml-2 text-sm opacity-70">({currentPlanFeatures.filter(feature => feature.id === f.id)[0].count})</span>
                            ) : ''}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="mt-auto mb-4">
                    {/* buy button */}
                    <button className={`items-center cursor-pointer font-bold justify-center w-full py-4 rounded-lg hover:opacity-90 border ${selectedPlan.comingSoon || (currentPlan && (selectedPlan.key === currentPlan.key) && (billingCycle.key === currentBillingCycleKey)) ? 'text-green-500 border-green-500' : 'text-white bg-green-500 border-transparent'}`}
                      onClick={() => selectPlan(selectedPlan.key, billingCycle.key)}
                      disabled={checkingOut || selectedPlan.comingSoon || (currentPlan && (selectedPlan.key === currentPlan.key) && (billingCycle.key === currentBillingCycleKey))}
                    >
                      {checkingOut ? (
                        <div>Redirecting...</div>
                      ) : selectedPlan.comingSoon ? (
                        <div>Contact for beta access</div>
                      ) : selectedPlan.key === "free" ? (
                        <div>Continue on limited free tier</div>
                      ) : currentPlan && (selectedPlan.key === currentPlan.key) && (billingCycle.key === currentBillingCycleKey) ? (
                        <div>Current Plan</div>
                      ) : currentPlan && ((selectedPlan.key !== currentPlan.key) || (billingCycle.key !== currentBillingCycleKey)) ? (
                         <div>Switch to {selectedPlan.name} {billingCycle.title}</div>
                      ) : !currentPlan && eligibleForTrialSubscription && (billingCycle.key !== 'lifetime') ? (
                        <div>Try {selectedPlan.name} for {TRIAL_PRICES[selectedPlan.key]}</div>
                      ) : !currentPlan ? (
                        <React.Fragment>
                          <div>Buy {selectedPlan.name} - ${selectedPlan.price[billingCycle.key + 'Total']}{billingCycle['totalFrequency']}</div>
                        </React.Fragment>
                      ) : 'Unknown...'}
                    </button>

                    {/* subtext */}
                    <div className="text-sm font-medium pt-2 opacity-75">
                      {checkingOut ? (
                        <div>{/*Please wait*/}</div>
                      ) : selectedPlan.comingSoon ? (
                        <div>Email fed@gummysearch.com</div>
                      ) : selectedPlan.key === "free" ? (
                        <div>{/*Upgrade when you need it*/}</div>
                      ) : currentPlan && (selectedPlan.key === currentPlan.key) && (billingCycle.key === currentBillingCycleKey) ? (
                        <div>No changes necessary</div>
                      ) : currentPlan && ((selectedPlan.key !== currentPlan.key) || (billingCycle.key !== currentBillingCycleKey)) ? (
                        <div>You will be prorated appropriately</div>
                      ) : !currentPlan && eligibleForTrialSubscription && (billingCycle.key !== 'lifetime') ? (
                        <div>{TRIAL_LENGTH} trial, then ${selectedPlan.price[billingCycle.key + 'Total']}{billingCycle['totalFrequency']}. Cancel anytime.</div>
                      ) : ''}
                    </div>

                    {/*<div className="py-4 pt-4 mx-auto text-sm opacity-50">No CC required</div>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionSelect;

