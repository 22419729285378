const getConversationCsvDict = (results) => {
    // gets the comments + submissions, and puts them into a format to be downloaded. passes to parent
    return results.map((r) => {
      const title = (r.title || "").replace(/"/g, '""').replace(/,/g, '\,').replace(/'/g, '\'');
      const body = (r.body || "").replace(/"/g, '""').replace(/,/g, '\,').replace(/'/g, '\'');
      const date = new Date(r.timestamp_utc * 1000)
      // const title = r.title;
      // const body = r.body;
      var data = {
        'id': r.id,
        'type': r._meta.type,
        'url': `https://reddit.com${r.link}`, // as r.url is sometimes the photo/video directly
        'score': r.score,
        'num_comments': r.num_comments,
        // 'time': r.timestamp_readable,
        'date': date.toISOString().split('T')[0], // universal format 2022-07-04
        'timestamp_utc': r.timestamp_utc,
        'subreddit_name': r.subreddit_name,
        'author': r.author,
        'is_removed': r.is_removed ? 'yes' : 'no',
        'is_deleted': r.is_deleted ? 'yes' : 'no',
        'body': body,
        'clicked': r.redirected ? 'yes' : 'no',
        'viewed': r.viewed ? 'yes' : 'no',
        'bookmarks': r.saved_conversations && r.saved_conversations.length && r.saved_conversations.map(c => c.saved_search_name).join(",") || '',
      }
      // submission-specific fields
      if (r._meta.type === 'reddit-submission'){
        data['title'] = title
        data['submission_type'] = r.type
        data['title_and_body'] =  `${title || ''}${title ? ' | ' : ''}${body || ''}`
      }

      // comment-specific fields
      if (r._meta.type === 'reddit-comment'){
        data['parent_id'] = r.parent_id; // determines if its top-level or not
        // data['submission_title'] = (r.submission_title || "").replace(/"/g, '""').replace(/,/g, '\,').replace(/'/g, '\'');
        // data['submission_body'] = (r.submission_body || "").replace(/"/g, '""').replace(/,/g, '\,').replace(/'/g, '\'');
      }

      return data
    });
}

const conversationContainsPhrase = (conversation, phrase) => {
  // conversation is a comment OR a submission!
  if (!conversation || !phrase) return false;

  const title = (conversation.title && conversation.title.toLowerCase()) || ''
  const body = (conversation.body && conversation.body.toLowerCase()) || ''
  const p = phrase.toLowerCase();
  
  if (title && title.includes(p)){
    return true
  } else if (body && body.includes(p)){
    return true
  }
  return false
}

// from a keyword query, splits into list of keywords
// "keyword" => ["keyword"]
// "keyword1,keyword2" => ["keyword1", "keyword2"]
// "keyword1 AND keyword2" => ["keyword1", "keyword2"]
// "keyword1 AND (keyword2 OR keyword3)" => ["keyword1", "keyword2", "keyword3"]
const splitKeywords = (keywordQuery) => {
  return keywordQuery
    .replaceAll('(', '')
    .replaceAll(')', '')
    .replaceAll('"', '')
    .replaceAll(' AND ', ',')
    .replaceAll(' NOT ', ',') // really we don't want these words, but they should be filtered out in backend
    .replaceAll(' OR ', ',')
    .replaceAll('title:', '') // in case targeting title
    .replaceAll('body:', ',') // in case targeting body
    .split(',') // split into list
    .filter(k => k) // cant be empty
    .map(k => k.trim()) // strip whitespace
}

const applyFrontendFilters = (filteredResults, frontendFilters) => {
  if (frontendFilters && frontendFilters.subreddits && frontendFilters.subreddits.length){
    const eligibleSubs = frontendFilters.subreddits.map(s => `r/${s}`);
    filteredResults = filteredResults.filter(r => eligibleSubs.includes(r.subreddit_name));
  }
  if (frontendFilters && frontendFilters.flair && frontendFilters.flair.length){
    filteredResults = filteredResults.filter(r => frontendFilters.flair.includes(r.flair_label));
  }
  if (frontendFilters && frontendFilters.type && frontendFilters.type.length){
    filteredResults = filteredResults.filter(r => frontendFilters.type.includes(r._meta.type));
  }
  if (frontendFilters && frontendFilters.submission_type && frontendFilters.submission_type.length){
    filteredResults = filteredResults.filter(r => (r._meta.type === 'reddit-submission') && frontendFilters.submission_type.includes(r.type));
  }
  if (frontendFilters && frontendFilters.status && frontendFilters.status.length){
    filteredResults = filteredResults.filter((r) => {
      const status = r.is_deleted ? 'deleted' : r.is_removed ? 'removed' : 'live';
      return frontendFilters.status.includes(status)
    });
  }
  if (frontendFilters && frontendFilters.yourStatus && frontendFilters.yourStatus.length){
    filteredResults = filteredResults.filter((r) => {
      const status = r.redirected ? 'clicked' : r.viewed ? 'viewed' : 'new';
      return frontendFilters.yourStatus.includes(status)
    });
  }
  if (frontendFilters && frontendFilters.score && frontendFilters.score.length){
    filteredResults = filteredResults.filter((r) => {
      return frontendFilters.score.some(s => {
        return (r.score >= s.min) && (r.score < s.max)
      })
    });
  }
  if (frontendFilters && frontendFilters.comments && frontendFilters.comments.length){
    filteredResults = filteredResults.filter((r) => {
      return frontendFilters.comments.some(s => {
        return (r.num_comments >= s.min) && (r.num_comments < s.max)
      })
    });
  }
  if (frontendFilters && frontendFilters.phrase && frontendFilters.phrase.length){
    filteredResults = filteredResults.filter((r) => {
      var found = false;
      frontendFilters.phrase.forEach(p => {
        if (conversationContainsPhrase(r, p)){
          found = true
        }
      })
      return found;
    });
  }
  if (frontendFilters && frontendFilters.timestamp_utc && frontendFilters.timestamp_utc.length){
    filteredResults = filteredResults.filter((r) => {
      return frontendFilters.timestamp_utc.some(s => {
        return (r.timestamp_utc >= s.min) && (r.timestamp_utc < s.max)
      })
    });
  }
  if (frontendFilters && frontendFilters.categories && frontendFilters.categories.length){
    filteredResults = filteredResults.filter((r) => {
      return frontendFilters.categories.some(s => {
        return r.matching_categories && (r.matching_categories.includes(s))
      })
    });
  }
  if (frontendFilters && frontendFilters.topics && frontendFilters.topics.length){
    filteredResults = filteredResults.filter((r) => {
      return frontendFilters.topics.some(s => {
        return r.matching_topics && (r.matching_topics.includes(s))
      })
    });
  }

  if (frontendFilters && frontendFilters.quick && frontendFilters.quick.length){
    if (frontendFilters.quick.includes('most-upvoted')){
      // filteredResults = filteredResults.filter((r) => {
      //   return frontendFilters.categories.some(s => {
      //     return r.matching_categories && (r.matching_categories.includes(s))
      //   })
      // });
    }
    if (frontendFilters.quick.includes('most-discussed')){

    }
    if (frontendFilters.quick.includes('not-removed')){
      filteredResults = filteredResults.filter(r => !r.is_deleted && !r.is_removed);
    }
    if (frontendFilters.quick.includes('no-duplicates')){
      // filters out any double posts by the same person with the same title
      var uniqueCombos = []; // combo of title & title
      filteredResults = filteredResults.filter((r) => {
        if (r._meta.type !== 'reddit-submission') return true;  // only for submissions
        const uniqueCombo = `${r.author}-${r.title}`;
        if (uniqueCombos.includes(uniqueCombo)){
          return false
        } else {
          uniqueCombos.push(uniqueCombo);
          return true;
        }
      });
    }
    if (frontendFilters.quick.includes('text-posts-only')){
      filteredResults = filteredResults.filter(r => r.type && r.type.includes('text'));
    }
    if (frontendFilters.quick.includes('not-viewed')){
      filteredResults = filteredResults.filter(r => !r.redirected && !r.viewed);
    }
  }

  if (frontendFilters && frontendFilters.sort){
    // frontend sort, different than the API version
    const sortOption = frontendFilters.sort;
    if (sortOption === "upvotes"){
      filteredResults = filteredResults.sort((a,b) => (a.score < b.score) ? 1 : ((b.score < a.score) ? -1 : 0));
    } else if (sortOption === "comments"){
      filteredResults = filteredResults.sort((a,b) => (a.num_comments < b.num_comments) ? 1 : ((b.num_comments < a.num_comments) ? -1 : 0));
    } else if (!sortOption || sortOption === "recent"){
      // should be default
      filteredResults = filteredResults.sort((a,b) => (a.timestamp_utc < b.timestamp_utc) ? 1 : ((b.timestamp_utc < a.timestamp_utc) ? -1 : 0));
    }
  }
  return filteredResults
}


export {
    getConversationCsvDict,
    conversationContainsPhrase,
    applyFrontendFilters,
    splitKeywords,
}

