import { FullStoryAPI } from 'react-fullstory';

// track an event
const trackEvent = (eventName, props) => {
  // GA
  if (typeof(window.gtag) !== "undefined"){
     window.gtag('event', eventName, props);
  }

  // plausible
  if (typeof(window.plausible) !== "undefined"){
    window.plausible(eventName, {
      'props': props
      // callback: () => console.info('Sent event'),
    });
  }

  // posthog
  if (typeof(window.posthog) !== "undefined"){
    window.posthog.capture(eventName, {
      'props': props
    });
  }

  // meta
  // if (typeof(window.fbq) !== "undefined"){
  //   // always track as custom event
  //   window.fbq('trackCustom', eventName, props);

  //   // also track certain events based on mapping
  //   const eventMap = {
  //     'Register': 'CompleteRegistration',
  //     'Subscribed': 'Purchase',
  //   }
  //   if (eventMap[eventName]){
  //     window.fbq('track', eventMap[eventName], {});
  //   }
  // }
}

// identify user to fullstory
const identifyUser = (userConfig) => {
  if (typeof(FullStoryAPI) !== "undefined" && userConfig){
    const userId = `${userConfig.env.name}-${userConfig.identity.id}`
    FullStoryAPI('identify', userId, {
      environment: userConfig.env.name,
      is_staff: userConfig.identity.is_staff,
      is_beta: userConfig.identity.is_beta,
      objective: userConfig.profile.objective,
      subscription: userConfig.subscription && userConfig.subscription.key,
    });
  }

  if ((typeof(window.posthog) !== "undefined") && userConfig){
    const userId = `${userConfig.env.name}-${userConfig.identity.id}`
    window.posthog.identify(
      userId,  // Replace 'distinct_id' with your user's unique identifier
      {
        email: userConfig.identity.email,
        environment: userConfig.env.name,
        objective: userConfig.profile.objective,
        subscription: userConfig.subscription && userConfig.subscription.key,
        billing_cycle: userConfig.subscription && userConfig.subscription.billing_cycle,
        login_days: userConfig.identity.login_days,
        is_new: userConfig.identity.is_new,
      },
    );
  }
}

const deidentifyUser = () => {
  if (typeof(FullStoryAPI) !== "undefined"){
    try {
      FullStoryAPI('anonymize') // ideally would use FullStoryAPI.anonymize() but it's not in the package yet
    } catch (error) {
      console.error("Tried FS anonymize but threw an error", error);
    }
  }
}

export {
    trackEvent,
    identifyUser,
    deidentifyUser,
}