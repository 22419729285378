import React, { useState, useEffect } from "react";
import ReactTooltip from 'react-tooltip';

import {
  TrendingUpIcon, InformationCircleIcon, QuestionMarkCircleIcon
} from '@heroicons/react/outline';

import ErrorBoundary from "../common/errorboundary";

import { readableNumber } from '../../utils/text';


// renders a public audience card
const PublicAudienceCard = ({
  audience, showIcons, className, onClick, userHasSaved
}) => {
  // subreddits in this audience
  const items = audience.items.sort((a,b) => (a.item.count_users > b.item.count_users) ? 1 : ((b.item.count_users > a.item.count_users) ? -1 : 0));

  // get the community icons to show
  const countIconsToShow = 7;
  const communitiesToShow = items.filter(s => s.item.icon).filter((s, i) => i < countIconsToShow);
  const communitiesToCollapse = items.filter(s => !communitiesToShow.includes(s));

  // get the stats
  const stats = {
    'total': items.map(s => s.item.count_users).reduce((a, b) => a + b, 0),
    'online': items.map(s => s.item.count_users_active).reduce((a, b) => a + b, 0),
  }
  
  return (
    <div key={audience.hash} onClick={() => userHasSaved ? null : onClick()} className={`group ${className || 'bg-gray-700 hover:bg-gray-600'} rounded-md shadow-md ${userHasSaved ? '' : 'cursor-pointer'} text-white flex p-4 flex-col`}>
      <ErrorBoundary>
        <div className="flex items-center">
          {/*<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-3 hidden opacity-25" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
          </svg>*/}

          <h2 className="text-xl font-semibold mr-auto">
            {audience.name}
          </h2>

          
          <ReactTooltip id={`audience-card-${audience.hash}`} place="top" effect="solid" backgroundColor="white" textColor="black" />
                  
          {userHasSaved ? (
            <span className="font-normal"
              data-for={`audience-card-${audience.hash}`} data-tip={`You have already saved this curated audience`}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="ml-2 h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
              </svg>
            </span>
          ) : ''}

          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-2 h-6 w-6 flex-shrink-0 text-gray-500"
            data-for={`audience-card-${audience.hash}`} data-tip={`Curated by GummySearch`}
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M4.098 19.902a3.75 3.75 0 005.304 0l6.401-6.402M6.75 21A3.75 3.75 0 013 17.25V4.125C3 3.504 3.504 3 4.125 3h5.25c.621 0 1.125.504 1.125 1.125v4.072M6.75 21a3.75 3.75 0 003.75-3.75V8.197M6.75 21h13.125c.621 0 1.125-.504 1.125-1.125v-5.25c0-.621-.504-1.125-1.125-1.125h-4.072M10.5 8.197l2.88-2.88c.438-.439 1.15-.439 1.59 0l3.712 3.713c.44.44.44 1.152 0 1.59l-2.879 2.88M6.75 17.25h.008v.008H6.75v-.008z" />
          </svg>


        </div>

        {items.length === 0 ? (
          <div className="h-40 text-center flex items-center">
            <div className="text-sm mx-auto opacity-50">No communities in this audience...</div>
          </div>
        ) : (
          <React.Fragment>
            <div className="">
              <div className="mt-2 flex sm:space-x-4 text-xs">
                <div className="flex items-center text-gray-300">
                  <svg className="h-4 w-4 mr-1 opacity-25" enableBackground="new 0 0 24 24" height="512" fill="currentColor" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg">
                    <path d="m21.325 9.308c-.758 0-1.425.319-1.916.816-1.805-1.268-4.239-2.084-6.936-2.171l1.401-6.406 4.461 1.016c0 1.108.89 2.013 1.982 2.013 1.113 0 2.008-.929 2.008-2.038s-.889-2.038-2.007-2.038c-.779 0-1.451.477-1.786 1.129l-4.927-1.108c-.248-.067-.491.113-.557.365l-1.538 7.062c-2.676.113-5.084.928-6.895 2.197-.491-.518-1.184-.837-1.942-.837-2.812 0-3.733 3.829-1.158 5.138-.091.405-.132.837-.132 1.268 0 4.301 4.775 7.786 10.638 7.786 5.888 0 10.663-3.485 10.663-7.786 0-.431-.045-.883-.156-1.289 2.523-1.314 1.594-5.115-1.203-5.117zm-15.724 5.41c0-1.129.89-2.038 2.008-2.038 1.092 0 1.983.903 1.983 2.038 0 1.109-.89 2.013-1.983 2.013-1.113.005-2.008-.904-2.008-2.013zm10.839 4.798c-1.841 1.868-7.036 1.868-8.878 0-.203-.18-.203-.498 0-.703.177-.18.491-.18.668 0 1.406 1.463 6.07 1.488 7.537 0 .177-.18.491-.18.668 0 .207.206.207.524.005.703zm-.041-2.781c-1.092 0-1.982-.903-1.982-2.011 0-1.129.89-2.038 1.982-2.038 1.113 0 2.008.903 2.008 2.038-.005 1.103-.895 2.011-2.008 2.011z"/>
                  </svg>
                  {items.length} Sub{items.length === 1 ? '' : 's'}
                </div>
                <div className="flex items-center text-gray-300">
                  <svg className="h-4 w-4 mr-1 opacity-25" xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                  </svg>
                  {readableNumber(stats.total)} Users
                </div>
                {/*<div className="flex items-center text-gray-300">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2 opacity-25" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5.636 18.364a9 9 0 010-12.728m12.728 0a9 9 0 010 12.728m-9.9-2.829a5 5 0 010-7.07m7.072 0a5 5 0 010 7.07M13 12a1 1 0 11-2 0 1 1 0 012 0z" />
                  </svg>
                  {readableNumber(stats.online)} Online
                </div>*/}

                {audience.calculated && audience.calculated.growth_members_monthly !== null ? (
                  <div className={`flex ${audience.calculated.growth_members_monthly ? 'text-gray-400' : 'text-gray-400'}`}>
                    <TrendingUpIcon className="h-4 w-4 mr-1" />
                    <div>{audience.calculated.growth_members_monthly.toFixed(2)}% /mo</div>
                  </div>
                ) : ''}
              </div>

              {showIcons ? (
                <dd className="flex justify-between items-baseline md:block">
                  <ReactTooltip id={`collection-${audience.hash}-icon`} effect="solid" backgroundColor="white" textColor="black"
                    className="w-60 text-center"
                  />
                  
                  <div className="flex flex-wrap mt-2 flex-row-reverse justify-end pr-4">
                    {communitiesToCollapse.length > 0 ? (
                      <div className="bg-gray-700 h-12 w-12 text-center py-3.5 rounded-md -mr-4 border-2 border-solid border-gray-800 group-hover:bg-gray-600 group-hover:border-gray-700 relative text-xs text-gray-400 font-semibold"
                        data-for={`collection-${audience.hash}-icon`}
                        data-tip={communitiesToCollapse.map(c => 'r/' + c.item.name).join(', ')}
                      >
                        +{communitiesToCollapse.length}
                      </div>
                    ) : ''}

                    {communitiesToShow.map(s => (
                      <div key={s.item.name} className="bg-gray-600 shadow-sm h-12 w-12 p-1 rounded-md -mr-4 border-2 border-solid border-gray-800 group-hover:border-gray-700 relative"
                        data-for={`collection-${audience.hash}-icon`} data-tip={`r/${s.item.name}`}
                      >
                        <img className="h-full w-full rounded-sm" src={s.item.icon} alt={s.item.name} />
                      </div>
                    ))}
                  </div>

                </dd>
              ) : ''}
              
            </div>
          </React.Fragment>
        )}

      </ErrorBoundary>
    </div>
  )
}

export default PublicAudienceCard;

