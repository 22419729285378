import React, { useState, useEffect } from "react"
import { connect } from 'react-redux';
import {
  Link, Switch, Route
} from "react-router-dom";
import { ModalRoute, ModalContainer } from 'react-router-modal';
import { Helmet } from "react-helmet";

import {
  PencilAltIcon, ChevronLeftIcon, PlusCircleIcon, LinkIcon,
  InformationCircleIcon, QuestionMarkCircleIcon, LightBulbIcon, SparklesIcon,
  SearchIcon,
} from '@heroicons/react/outline'
import { Popover, Transition } from '@headlessui/react'

import { audiencesOperations } from "../../redux/audiences"
import { userOperations } from "../../redux/user"
import { collectionsService } from '../../services/collections';
import { searchesService } from '../../services/searches';
import { languageService } from '../../services/language';

import { localStorageAvailable } from '../../utils/localStorage';
import { getUserSuggestions } from '../../components/audiences/constants/suggestions';

import Layout from "../../components/layout";
import ErrorBar from "../../components/common/errorBar";
import NavBar from "../../components/common/navbar";
import ErrorBoundary from "../../components/common/errorboundary";
import PropsRoute from '../../components/common/propsroute.jsx';
import LoaderFancy from '../../components/common/loaderfancy.jsx';
import DrawerRoute from "../../components/common/drawerroute";

import AudienceSubreddits from "../../components/audiences/audiencesubreddits";
import AudienceTopics from "../../components/audiences/audienceTopics"
import AudienceThemes from "../../components/audiences/audienceThemes"
import AudienceAskQuestion from "../../components/audiences/audienceAskQuestion"
// import AudienceTrack from "../../components/audiences/audienceTrack";
import AudienceHome from "../../components/audiences/audienceHome";
import AudienceInfoModal from "../../components/audiences/audienceInfoModal";
import AudienceEditModal from "../../components/audiences/audienceEditModal";
import AudienceAddModal from "../../components/audiences/audienceAddModal";
import AudienceShareModal from "../../components/audiences/audienceShareModal";
// import AudienceSuggestionDrawer from "../../components/audiences/audiencesuggestiondrawer";
import AudienceBrowseDrawer from "../../components/audiences/audiencebrowsedrawer";
import TrackedKeywordDrawer from "../../components/conversations/trackedkeyworddrawer";



const Audience = ({
  history, 
  location, 
  match, 
  currentUser,

  fetchingAudiences, audiences, fetchAudiences,
  updateChecklist,
}) => {
  //state
  const { hash } = match.params;
  const [collection, setCollection] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [loadingItems, setLoadingItems] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [loadingSuggestions, setLoadingSuggestions] = useState(false);
  const [topics, setTopics] = useState([]);
  const [loadingTopics, setLoadingTopics] = useState(false);

  const baseUrl = `/audience/${hash}/`;
  var tabs = [
    {'name': 'Search', 'link': baseUrl, exact: true, 'icon': SearchIcon},
    {'name': 'Ask', 'link': `${baseUrl}ask/`, 'icon': SparklesIcon},
    {'name': 'Subreddits', 'link': `${baseUrl}subs/`, count: !loadingItems && items.length},
    {'name': 'Topics', 'link': `${baseUrl}topics/`, count: !loadingTopics && topics.length},
    {'name': 'Themes', 'link': `${baseUrl}themes/`, count: !loadingSuggestions && suggestions.length},
  ]


  // effects
  useEffect(() => {
    // get the collection
    fetchCollection();

    // also get the collection's items
    fetchCollectionItems();

    // fetch collection suggestions/themes
    fetchSuggestions();

    // fetch the topics
    fetchCollectionTopics();
  }, [hash]);

  useEffect(() => {
    if (!fetchingAudiences && !audiences.length){
      fetchAudiences()
    }

    checkForWelcomeModal();
  }, []); // Load up lists of all users audiences

  // acions
  const fetchCollection = () => {
    setLoading(true)
    collectionsService.getCollection(hash, (results) => {
      if (results && results.data){
        setCollection(results.data)
        setLoading(false)
      }
    }, (response, error) => {
      setLoading(false)
      setError(error);
    })

    // after fetching, also save it to be the most recent viewed collection
    const key = 'audience-view-order'
    if (localStorageAvailable() && !localStorage.getItem(key)){
      // history.push(`/audience/${hash}/info/`);
      localStorage.setItem(key, hash)
    } else if (localStorageAvailable() && localStorage.getItem(key)){
      // put it to the front of the line
      const newValue = `${hash},${localStorage.getItem(key).replace(hash,'')}`.replace(',,',',')
      localStorage.setItem(key, newValue)
    }
  }

  const fetchCollectionItems = () => {
    setLoadingItems(true)
    collectionsService.getCollectionItems(hash, (results) => {
      if (results && results.data){
        setItems(results.data.filter(i => i.external_type === "RSR"))  // ensure only subreddit for now. if we add, modify  
        setLoadingItems(false)
      }
    }, (response, error) => {      // setLoading(false)
      setError(error);
    })
  }

  const fetchCollectionTopics = () => {
    setLoadingTopics(true)
    const params = {};
    collectionsService.getCollectionTopics(hash, params, (results) => {
      if (results && results.data){
        setTopics(results.data.filter(t => typeof t.stats.velocity_current === "number").sort((a,b) => (a.stats.velocity_current < b.stats.velocity_current) ? 1 : ((b.stats.velocity_current < a.stats.velocity_current) ? -1 : 0)))
        setLoadingTopics(false)
      }
    }, (response, error) => {
      setError(error);
    })
  }

  const checkForWelcomeModal = () => {
    // shows welcome modal if user hasn't seen it yet (and they are new)
    const key = 'viewed-audience-welcome-modal'
    if (currentUser.identity.is_new && localStorageAvailable() && !localStorage.getItem(key)){
      history.push(`/audience/${hash}/info/`);
      localStorage.setItem(key, 'yes')

      // also update the progress bar since this is their first audience
      updateChecklist(currentUser.profile.id)
    }
  }

  const fetchSuggestions = () => {
    if (loadingSuggestions || (suggestions && suggestions.length)) return;

    // fetch themes for this collection
    setLoadingSuggestions(true)
    const params = {};
    collectionsService.getCollectionThemes(hash, params, (results) => {
      if (results && results.data){
        // enrich suggestion categories with the keywords from the API
        setSuggestions(getUserSuggestions(results.data))
        setLoadingSuggestions(false)
      }
    }, (response, error) => {
      setLoadingSuggestions(false)
      setError(error);
    })
  }

  const addCommunity = (name) => {
    // add a single communtiy to the collection
    const items = [{'external_type': 'RSR', 'external_id': name}];
    collectionsService.addCollectionItems(collection.hash, items, (results) => {
      fetchCollectionItems();
    }, (response, error) => {
      console.log(error)
      error("Error adding community to collection");
    })
  }

  const hideCommunity = (name) => {
    // right now just adds to the user's hidden communities
    // take feedback as to where we hide it from
    searchesService.addHiddenCommunity("RSR", name, name, {
      'type': 'collection',
      'type_id': collection.id,
    });
  }

  return (
    <Layout currentUser={currentUser} >
      <Helmet><title>Audience</title></Helmet>
      
      <ErrorBoundary>
        {collection ? (
          <div className={`${loading ? 'opacity-50' : ''}`}>

            <div className="flex items-center flex-wrap sm:-mt-3 pb-2">
              <div>
                {/*<div>
                  <nav className="flex" aria-label="Breadcrumb">
                    <ol role="list" className="flex items-center space-x-4">
                      <li className="flex">
                        <Link to="/audiences/" className="flex items-center text-sm font-medium text-gray-400 hover:text-gray-200">
                          <ChevronLeftIcon className="flex-shrink-0 -ml-2 h-5 w-5 text-gray-500" aria-hidden="true" />
                          Audiences
                        </Link>
                      </li>
                    </ol>
                  </nav>
                </div>*/}
                <div className="flex-1 min-w-0">
                  <h2 className="text-2xl font-bold leading-7 text-white sm:text-3xl sm:truncate flex">
                    <Link to="/audiences/" className="flex items-center text-sm font-medium text-gray-400 hover:text-gray-200">
                      <ChevronLeftIcon className="flex-shrink-0 -ml-1 h-6 w-6 text-gray-500 hover:text-gray-400 mr-2" aria-hidden="true" />
                    </Link>
                    {collection.name}
                  </h2>
                </div>
              </div>
              <div className="ml-4 sm:ml-auto flex mt-2 md:mt-0">
                <Link to={`/audience/${collection.hash}/info/`}
                  type="button"
                  className="appearance-none sm:ml-3 inline-flex items-center px-4 py-2 border border-solid border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-700 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-cyan-500"
                >
                  <InformationCircleIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                  Info
                </Link>


                <Link to={`/audience/${collection.hash}/edit/`}
                  type="button"
                  className="appearance-none ml-3 inline-flex items-center px-4 py-2 border border-solid border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-700 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-cyan-500"
                >
                  <PencilAltIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                  Edit
                </Link>

                <Link to={`/audience/${collection.hash}/share/`}
                  type="button"
                  className="appearance-none ml-3 inline-flex items-center px-4 py-2 border border-solid border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-700 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-cyan-500"
                >
                  <LinkIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                  Share{collection.shared_report_enabled ? 'd' : ''}
                </Link>

                <Link to={`/audience/${collection.hash}/add/`}
                  type="button"
                  className="appearance-none ml-3 inline-flex items-center px-4 py-2 border border-solid border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-cyan-500 hover:bg-cyan-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-cyan-500"
                >
                  <PlusCircleIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                  Add
                </Link>
              </div>
            </div>

            <div className="border-b border-solid border-gray-800">
              <NavBar tabs={tabs}/>
            </div>

            <ErrorBoundary>
              <Switch>
                <ModalRoute component={AudienceInfoModal}
                  path={`/audience/:collectionHash/info/`}
                  parentPath={`/audience/${collection.hash}/`}
                  props={{
                    'collection': collection,
                    // 'refetch': fetchCollection,
                  }}
                />
                <ModalRoute component={AudienceEditModal}
                  path={`/audience/:collectionHash/edit/`}
                  parentPath={`/audience/${collection.hash}/`}
                  props={{
                    'collection': collection,
                    'refetch': fetchCollection,
                  }}
                />
                <ModalRoute component={AudienceAddModal}
                  path={`/audience/:collectionHash/add/`}
                  parentPath={`/audience/${collection.hash}/`}
                  props={{
                    'collection': collection,
                    'collectionItems': items,
                    'addCommunity': addCommunity,
                    'hideCommunity': hideCommunity,
                    'refetch': fetchCollectionItems,
                  }}
                />
                <ModalRoute component={AudienceShareModal}
                  path={`/audience/:collectionHash/share/`}
                  parentPath={`/audience/${collection.hash}/`}
                  props={{
                    'collection': collection,
                    'refetch': fetchCollectionItems,
                    'currentUser': currentUser,
                    'refetch': fetchCollection,
                  }}
                />
                {/* Show browse when using keyword search on home page*/}
                <ModalRoute component={AudienceBrowseDrawer}
                  path={`/audience/:collectionHash/search/`}
                  parentPath={`/audience/${collection.hash}/`}
                  props={{
                    'collection': collection,
                    'currentUser': currentUser,
                    'baseUrl': `/audience/${collection.hash}/search/`,
                    // 'refetch': fetchCollection,
                  }}
                />


                {/*<ModalRoute component={TrackedKeywordDrawer}
                  path={`/audience/:collectionHash/tracked/:savedSearchHash/`}
                  parentPath={`/audience/${collection.hash}/tracked/`}
                  props={{
                    'currentUser': currentUser,
                    'urlRoot': `/audience/${collection.hash}/tracked/`,
                    // TODO: need to implement
                    'refetchAll': () => {},
                    // 'refetchAll': () => fetchSavedSearches({'includeStats': true}),
                  }}
                />*/}
              </Switch>

              <ModalContainer />
            </ErrorBoundary>

            <div className="pt-0">
              <Switch>
                <PropsRoute path={`/audience/${collection.hash}/themes/`} component={AudienceThemes}
                  collection={collection} subreddits={items}
                  topics={topics} loadingTopics={loadingTopics} fetchTopics={fetchCollectionTopics}
                  suggestions={suggestions} loadingSuggestions={loadingSuggestions}
                />
                <PropsRoute path={`/audience/${collection.hash}/topics/`} component={AudienceTopics}
                  collection={collection} subreddits={items}
                  topics={topics} loadingTopics={loadingTopics} fetchTopics={fetchCollectionTopics}
                  suggestions={suggestions} loadingSuggestions={loadingSuggestions}
                />
                <PropsRoute path={`/audience/${collection.hash}/subs/`} component={AudienceSubreddits}
                  collection={collection} items={items}
                  loadingItems={loadingItems} setItems={setItems}
                  showSimilarTip={true} fetchCollectionItems={fetchCollectionItems}
                />
                <PropsRoute path={`/audience/${collection.hash}/ask/`} component={AudienceAskQuestion}
                  collection={collection} subreddits={items} topics={topics} suggestions={suggestions} 
                />
                <PropsRoute path={`/audience/${collection.hash}/`} component={AudienceHome}
                  collection={collection} subreddits={items} loadingSubreddits={loadingItems}
                  topics={topics} loadingTopics={loadingTopics}
                  themes={suggestions} loadingThemes={loadingSuggestions}
                />

                {/*<Redirect exact path="/account/" to="/account/subscription/" />*/}
              </Switch>
            </div>
          </div>
        ) : loading ? (
          <LoaderFancy />
        ) : ''}

        {error ? (
          <ErrorBar error={error} setError={setError} />
        ) : ''}
      </ErrorBoundary>
    </Layout>
  );
}

const mapStateToProps = state => {
  const {
    audiences,
    fetchingAudiences
  } = state.audiences;

  return {
    audiences,
    fetchingAudiences,
  }
};

const mapDispatchToProps = (dispatch) => {
  const fetchAudiences = (callback, onError) => {
    dispatch(audiencesOperations.fetchAudiences(callback, onError))
  };
  const updateChecklist = (userId, callback, onError) => {
    dispatch(userOperations.updateChecklist(userId, callback, onError))
  };
  return {
    fetchAudiences,
    updateChecklist,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Audience);

