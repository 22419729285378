import {
  FireIcon, ThumbUpIcon, QuestionMarkCircleIcon, EmojiSadIcon, EmojiHappyIcon,
  LightBulbIcon, SupportIcon, PuzzleIcon, UsersIcon, CurrencyDollarIcon, ArrowUpIcon,
  NewspaperIcon, SpeakerphoneIcon,
} from '@heroicons/react/outline'

// map of icon strings to icons
// const iconMap = {
//   'puzzle': PuzzleIcon,
//   'question-mark-circle': QuestionMarkCircleIcon,
//   'emoji-sad': EmojiSadIcon,
//   'light-bulb': LightBulbIcon,
//   'currency-dollar': CurrencyDollarIcon,
//   'arrow-up': ArrowUpIcon,
// }

// track an event. currently going to GA
const SUGGESTIONS = [
  {
    'name': 'Hot Discussions',
    'slug': 'hot',
    'icon': FireIcon,
    'description': 'Popular discussions this week',
    'params': {'sort': 'hot', 'backend': 'praw', 'timeframe': 'week', 'limit': 100},
    'sortOptions': ['Comments', 'Upvotes', 'Recent'],
  },
  {
    'name': 'Top Content',
    'slug': 'top',
    'icon': ThumbUpIcon,
    'description': 'Best-performing content of past month',
    'params': {'sort': 'top', 'backend': 'praw', 'timeframe': 'month', 'limit': 100},
    'sortOptions': ['Upvotes', 'Comments', 'Recent'],
  },
  {
    'name': 'Solution Requests',
    'slug': 'solutions',
    'icon': PuzzleIcon,
    'description': 'People asking for tools & solutions',
    'params': {'limit': 100},
    'requiresStarter': true, // can be done on free tier for now
    'sortOptions': ['Comments', 'Upvoted', 'Recent'],
    // 'keywords': [
    //   // 'Looking for a',
    //   'Any tools',
    //   'Is there a tool',
    //   'Any apps',
    //   'Any websites',
    //   'Any sites',
    //   'Any solutions',
    //   'I wish there was',
    //   'Any software',
    // ],
  },
  {
    'name': 'Pain & Anger',
    'slug': 'pain',
    'icon': EmojiSadIcon,
    'description': 'People expressing pain points & frustrations',
    'params': {'limit': 100},
    'requiresStarter': true, // can be done on free tier for now
    'sortOptions': ['Comments', 'Upvoted', 'Recent'],
    // 'keywords': [
    //   'Frustrating',
    //   'Hard time',
    //   'Struggling',
    //   // 'Stuck',
    //   // 'Buggy',
    //   'The worst',
    //   'Hate',
    //   'Annoying',
    //   // 'I dislike',
    //   'It sucks when',
    //   "I can't because",
    // ],
  },
  // {
  //   'name': 'Agreement',
  //   'slug': 'agreement',
  //   'icon': UsersIcon,
  //   'description': 'People looking for others with similar thoughts',
  //   'params': {'limit': 100},
  //   'keywords': [
  //     'Anyone else',
  //     'Anybody else',
  //   ],
  // },
  {
    'name': 'Advice Requests',
    'slug': 'resources',
    'icon': QuestionMarkCircleIcon,
    'description': 'People asking for advice & resources',
    'params': {'limit': 100},
    'requiresStarter': true, // can be done on free tier for now
    'sortOptions': ['Comments', 'Upvoted', 'Recent'],
    // 'keywords': [
    //   'Does anyone know',
    //   'Any recommendations',
    //   'Anyone know of',
    //   'Anyone know how',
    //   'I am looking for',
    //   'Do you recommend',
    //   'How to',
    //   'How else',
    //   'Anyone else',
    //   'Anybody else',
    // ],
  },
  {
    'name': 'Ideas',
    'slug': 'ideas',
    'icon': LightBulbIcon,
    'description': 'People suggesting ideas',
    'params': {'limit': 100},
    'requiresStarter': true, // can be done on free tier for now
    'sortOptions': ['Comments', 'Upvoted', 'Recent'],
    // 'keywords': [
    //   'There should be',
    //   'Someone should',
    //   'An idea',
    // ],
  },
  {
    'name': 'Money Talk',
    'slug': 'money',
    'icon': CurrencyDollarIcon,
    'description': 'People talking about spending money',
    'params': {'limit': 100},
    'requiresStarter': true, // can be done on free tier for now
    'sortOptions': ['Comments', 'Upvoted', 'Recent'],
    // 'keywords': [
    //   'Pay for',
    //   'Spend money',
    //   'Expensive',
    //   'Price',
    //   'Pricing',
    //   'Budget',
    // ],
  },
  {
    'name': 'Opportunities',
    'slug': 'opportunities',
    'icon': ArrowUpIcon,
    'description': 'Conversations about things that can be improved',
    'params': {'limit': 100},
    'requiresStarter': true, // can be done on free tier for now
    'sortOptions': ['Comments', 'Upvoted', 'Recent'],
    // 'keywords': [
    //   'Automate',
    //   'Alternative to',
    //   'Replacement',
    //   'Bulk',
    //   'Manually',
    //   'Buggy',
    //   'Too slow',
    //   'Spreadsheet',
    //   'Looking for alternatives',
    // ],
  },
  {
    'name': 'Self-Promotion',
    'slug': 'self',
    'icon': SpeakerphoneIcon,
    'description': 'People launching products & services',
    'params': {'limit': 100},
    'requiresStarter': true, // can be done on free tier for now
    'sortOptions': ['Comments', 'Upvoted', 'Recent'],
  },
  {
    'name': 'News',
    'slug': 'news',
    'icon': NewspaperIcon,
    'description': 'Conversations about current news & events',
    'params': {'limit': 100},
    'requiresStarter': true, // can be done on free tier for now
    'sortOptions': ['Comments', 'Upvoted', 'Recent'],
  },
]

const getUserSuggestions = (apiResults) => {
  // enrish suggestions with keywords, based on what we have above & the API results
  return SUGGESTIONS.map((category) => {
    const apiCategory = apiResults.filter(c => c.slug === category.slug)[0];
    if (apiCategory){
      category['keywords'] = apiCategory.keywords;
      category['icon_name'] = apiCategory.icon;
      category['count'] = apiCategory.count;
    }
    return category
  })
}


export {
    SUGGESTIONS,
    getUserSuggestions,
}